<script>
export default {
  name: 'requiredPin',
  render(createElement) {
    return createElement('span', { class: 'pin' }, '*')
  }
}
</script>

<style scoped lang="sass">
.pin
  color: $red
  font-weight: bold
</style>
